/**
 * @fileoverview state for the event
 */
import { SET_EVENT } from "~~/redux/actionList";

const initialState = {
  id: null,
  hashid: null,
  name: null,
  description: null,
  startTime: null,
  endTime: null,
  timeZone: null,
  primaryColor: null,
  secondaryColor: null,
  hashtag: null,
  logoUrl: null,
  iconUrl: null,
  coverImageUrl: null,
  organization: {
    id: null,
    hashid: null,
    name: null,
    email: null,
    about: null,
    timeZone: null,
    twitterProfile: null,
    instagramProfile: null,
    linkedinProfile: null,
    facebookProfile: null,
    websiteUrl: null,
    logoUrl: null,
    iconUrl: null,
    primaryColor: null,
    secondaryColor: null,
    hashtag: null,
  },
  stages: [],
  stage: {},
};

export default (state = initialState, action) => {
  const { eventState } = action;

  switch (action.type) {
    case SET_EVENT:
      return { ...state, ...eventState };
    default:
      return state;
  }
};
